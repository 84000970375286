/* Tour */
.invoice-page-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 1038;
  opacity: 0.5;
}
.invoice-page-wrapper{
  position: absolute;
  z-index: 1039;
  background: white;
  padding: 25px;
  margin-bottom: 60px;
  left: 50%;
  top: 60px;
  transform: translate(-50%, 0);
}
/* ~Tour */
