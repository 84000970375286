.invoice-help {
  @import "./theme1.scss";
  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, .125);

  // body
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  min-width: 320px;
  background: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 25px;
  // letter-spacing: 0.9;
  line-height: 1;
  color: #404041;
  table {
    font-size: 25px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: inherit;
    line-height: inherit;
    color: inherit
  }
  .section-invoice-head {
    h3.inline {
      font-size: 26px;
    }
    .page-title {
      width: 20%;
    }
    .period{
      text-align: right;
    }
  }

  .page-separator{
    margin: 5px 0;
    border-top: 1px dashed #aeaeae;
  }

  .shell {
    min-width: auto;
  }

  .table-default-primary.complex-sumup-wrapper {
  	max-width: 640px;
  }
  .sumup .table-default .complex-sumup th:first-child,
  .invoice-help .sumup .table-default .complex-sumup td:first-child {
    width: 350px;
  }

  @media (max-width: 1023px) {
    .container {
      width: 680px
    }
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .container {
      width: 850px;
    }
    .footer .credentials {
      margin-left: 100px;
    }
  }
  @media (min-width: 1201px) {
      .container {
        width: 1024px;
      }
      .footer .credentials {
        .text {
          vertical-align: bottom;
        }
      }
  }

  .footer {
    .section {
      border-top: none;
      padding: 15px;
    }
  //   ul.list-contacts li { font-size: 17px; }
  //   ul.list-contacts li a { font-size: 17px; }
  //   img.billrun-logo { width:100px; }
  //   .sumup .table-default { margin-bottom:0px; }
  //   .credentials .text {
  //     display: inline-block;
  //     vertical-align: middle;
  //     margin-right: 5px;
  //   }
  }

  // * {
  //   font-family: 'Roboto Condensed', sans-serif;
  //   // font-size: 25px;
  // }
  // i.fa {
  //   font-family: 'FontAwesome';
  // }
}
