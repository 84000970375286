/* fix line height bug: https://github.com/mu29/react-stepper/issues/21*/

.stepper-container div div div div {
  a {
    line-height: 22px!important;
  }
  span {
    line-height: 22px!important;
  }
}
