/* React-Select */
.react-select__control {
  border-radius: 0!important;
  min-height: 39px!important;
}
.react-select__menu {
  z-index: 5!important;
}
.react-select__placeholder {
  font-size: 90%;
}
.react-select__multi-value__remove {
  background-color: #e6e6e6!important;
  color: #6f6f6f!important;
  &:hover,
  &:focus{
      background-color: #e6e6e6!important;
      color: #222222!important;
  }
}
.react-select__control--is-focused {
  border-color: #66afe9!important;
  outline: 0!important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)!important;
}
.form-group {
  &.has-error {
    .react-select__control,
    .react-select__control--is-focused  {
      border-color: #f04124!important;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)!important;;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)!important;;
    }
  }
}

.Field.not-top-border .react-select__control {
  border-top: none !important;
}

//
// .Select-menu-outer{
// }
// .Select-control{
//   border-radius: 0!important;
//   height: 39px!important;
// }
// .Select-search-prompt {
//   padding: 5px!important;
// }
// .has-value > .Select-control > .Select-placeholder{
//   color: #6f6f6f!important;
// }
//
// .Select-item{
//   color: #6f6f6f!important;
//   background-color: #e7e7e7!important;
//   border-color: #dddddd!important;
//   border-radius: 0!important;
//   font-size: 13px;
// }
// .Select-item-icon{
//   padding: 3px;
//   font-weight: bold;
//   border: none;
// }
// .Select-item-icon:hover, .Select-item-icon:focus {
//   background-color: #eeeeee!important;
//   color: #222222!important;
// }
// .Select-item:hover,.Select-item:focus{
//   background-color: #eeeeee!important;
//   color: #222222!important;
// }
// .Select-menu-outer .Select-option.is-selected {
//   font-weight: bolder;
//   background-color: #eeeeee!important;
//   color: #222222!important;
// }
/* ~React-Select */
