@import "../mixin";

.collections-list {

  .collections-item-display {
    height: 125px;
    margin-bottom: 10px;
    min-width: 205px;

    .panel {
      height: 100%;
    }

    .panel-default>.panel-heading {
      background: #ffffff;
    }
    .panel-body {
      padding: 5px 15px;

      > div {
        margin: 3px 0 5px;
      }

      button {
        margin: 10px 0 5px;
        width: 48%;
      }
    }

    &.add-new {
      text-align: center;
      button {
        @include vertical-align();
        font-size: 14px;
      }
    }
  }
}
