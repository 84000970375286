#EventsTab {
  UL.nav-tabs {
    li {
      &:last-child {
        float: right;
      }
    }
  }
  .filter-info {
    background: none;
  }
}